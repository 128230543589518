var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 fill-height"},[(_vm.success)?_c('v-alert',{staticClass:"ma-3",attrs:{"type":"success","dense":"","dismissible":""},domProps:{"textContent":_vm._s(_vm.success)}}):_vm._e(),(_vm.error)?_c('v-alert',{staticClass:"ma-3",attrs:{"type":"error","dense":"","dismissible":""},domProps:{"textContent":_vm._s(_vm.error)}}):_vm._e(),_c('pibot-page-heading',[_vm._v(" Incidents ")]),_c('v-container',[_c('v-fab-transition',[_c('v-btn',{attrs:{"bottom":"","color":"primary darken-1","fab":"","fixed":"","large":"","right":""},on:{"click":function($event){return _vm.newItem()}}},[_c('v-icon',[_vm._v("add")])],1)],1),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"incident-data-table",attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"item-key":'_id',"loading":_vm.loading,"options":_vm.options,"expanded":_vm.expanded,"fixed-header":"","single-expand":"","show-expand":""},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-switch',{staticClass:"pa-0",attrs:{"label":"Show only escalated incidents","color":"primary darken-1","dense":""},model:{value:(_vm.filterEscalated),callback:function ($$v) {_vm.filterEscalated=$$v},expression:"filterEscalated"}}),_c('small',{domProps:{"textContent":_vm._s(("* All dates are presented in " + _vm.UTC))}})]},proxy:true},{key:"item.escalate",fn:function(ref){
var item = ref.item;
return [(!item.escalated)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":"error darken-1","outlined":""},on:{"click":function($event){return _vm.startEscalate(item)}}},'v-chip',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s('add_alarm')}})],1)]}}],null,true)},[_c('span',[_vm._v("Escalate to incident")])]):_c('v-chip',{attrs:{"color":"warning darken-1","outlined":""}},[_c('v-icon',{domProps:{"textContent":_vm._s('alarm_on')}})],1)]}},{key:"item.asset",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.pipeline_name)+" ")]}},{key:"item.incident_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.incident_name)+" ")]}},{key:"item.incident_description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.incident_description)+" ")]}},{key:"item.absolute_distance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.absolute_distance)+" ")]}},{key:"item.date_of_notification",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date_of_notification ? _vm.formatDate(item.date_of_notification) : 'N/A')+" ")]}},{key:"item.date_of_occurance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date_of_occurance ? _vm.formatDate(item.date_of_occurance) : 'N/A')+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getLastUpdate(item))+" ")]}},{key:"item.severity",fn:function(ref){
var item = ref.item;
return [_c('pibot-risk-chip',{attrs:{"risk":_vm.getSeverityValue(item, 'severity'),"color":_vm.getColor(item),"small":"","tooltip":true}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('pibot-context-menu',{attrs:{"actions":_vm.getActions(item)},on:{"click":function (action) { return !!action.fn && action.fn(item); }}})]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [(item.escalated)?_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return expand(!isExpanded)}}},[(isExpanded)?_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiChevronUp))]):_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiChevronDown))])],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"py-3",attrs:{"colspan":headers.length}},[_c('pibot-eprs-expand-item',{attrs:{"item":item}})],1)]}}],null,true)})],1)],1)],1),(_vm.dialog)?_c('v-dialog',{attrs:{"width":"450px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('pibot-datetime-picker-card',{attrs:{"datetime":+new Date()},on:{"close-dialog":function($event){_vm.dialog = false},"escalate":_vm.escalate}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }