<template>
  <div class="w-100 fill-height">
    <!-- Alerts -->
    <v-alert
      v-if="success"
      v-text="success"
      type="success"
      dense
      dismissible
      class="ma-3"
    />

    <v-alert
      v-if="error"
      v-text="error"
      type="error"
      dense
      dismissible
      class="ma-3"
    />

    <pibot-page-heading>
      Incidents
    </pibot-page-heading>

    <v-container>
      <!-- FAB -->
      <v-fab-transition>
        <v-btn
          bottom
          color="primary darken-1"
          fab
          fixed
          large
          right
          @click="newItem()"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </v-fab-transition>

      <!-- Table -->
      <v-card flat>
        <v-card-text class="pa-0">
          <v-data-table
            :headers="headers"
            :items="filteredItems"
            :item-key="'_id'"
            :loading="loading"
            :options.sync="options"
            :expanded.sync="expanded"
            class="incident-data-table"
            fixed-header
            single-expand
            show-expand
          >
            <!-- Filter escalated rows -->
            <template v-slot:top>
              <v-switch
                v-model="filterEscalated"
                label="Show only escalated incidents"
                class="pa-0"
                color="primary darken-1"
                dense
              />
              <small v-text="`* All dates are presented in ${UTC}`"/>
            </template>

            <!-- Escalate -->
            <template #[`item.escalate`]="{ item }">
              <v-tooltip bottom v-if="!item.escalated">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    color="error darken-1"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    @click="startEscalate(item)"
                  >
                    <v-icon v-text="'add_alarm'" />
                  </v-chip>
                </template>
                <span>Escalate to incident</span>
              </v-tooltip>
              <v-chip v-else color="warning darken-1" outlined>
                <v-icon v-text="'alarm_on'" />
              </v-chip>
            </template>

            <!-- Asset name -->
            <template #[`item.asset`]="{ item }">
              {{ item.pipeline_name }}
            </template>

            <!-- Incident name -->
            <template #[`item.incident_name`]="{ item }">
              {{ item.incident_name }}
            </template>

            <!-- Asset name -->
            <template #[`item.incident_description`]="{ item }">
              {{ item.incident_description }}
            </template>

            <!-- Absolute distance -->
            <template #[`item.absolute_distance`]="{ item }">
              {{ item.absolute_distance }}
            </template>

            <!-- Date of occurence -->
            <template #[`item.date_of_notification`]="{ item }">
              {{ item.date_of_notification ? formatDate(item.date_of_notification) : 'N/A' }}
            </template>

            <!-- Date of occurence -->
            <template #[`item.date_of_occurance`]="{ item }">
              {{ item.date_of_occurance ? formatDate(item.date_of_occurance) : 'N/A' }}
            </template>

            <!-- Last update -->
            <template #[`item.updated_at`]="{ item }">
              {{ getLastUpdate(item) }}
            </template>

            <!-- Severity / Risk -->
            <template #[`item.severity`]="{ item }">
              <pibot-risk-chip :risk="getSeverityValue(item, 'severity')" :color="getColor(item)" small :tooltip="true"/>
            </template>

            <!-- Actions -->
            <template #[`item.actions`]="{ item }">
              <pibot-context-menu :actions="getActions(item)" @click="(action) => !!action.fn && action.fn(item)" />
            </template>

            <!-- Expand row button -->
            <template #[`item.data-table-expand`]="{ item, isExpanded, expand }">
              <v-btn @click="expand(!isExpanded)" v-if="item.escalated" small icon>
                <v-icon v-if="isExpanded">{{ icons.mdiChevronUp }}</v-icon>
                <v-icon v-else>{{ icons.mdiChevronDown }}</v-icon>
              </v-btn>
            </template>

            <!-- Expanded item -->
            <template #expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="py-3">
                <pibot-eprs-expand-item :item="item" />
              </td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>

    <!-- Datetime picker dialog -->
    <v-dialog v-model="dialog" v-if="dialog" width="450px">
      <pibot-datetime-picker-card
        :datetime="+new Date()"
        @close-dialog="dialog = false"
        @escalate="escalate"
      />
    </v-dialog>
  </div>
</template>

<script>
import mapboxBasicsMixin from '@/utils/mixins/mapbox/mapboxBasics.mixin.js'
import { mapState, mapActions, mapMutations } from 'vuex'
import moment from 'moment'
import _ from 'lodash'
import { mdiChevronDown, mdiChevronUp } from '@mdi/js'

export default {
  name: 'pibot-eprs-overview',
  mixins: [mapboxBasicsMixin],
  components: {
    'pibot-page-heading': () => import('@/components/typography/headings/PageHeading'),
    'pibot-risk-chip': () => import('@/components/chips/RiskChip'),
    'pibot-context-menu': () => import('@/components/buttons/ContextMenu'),
    'pibot-eprs-expand-item': () => import('./ExpandItem'),
    'pibot-datetime-picker-card': () => import('@/components/cards/DatetimePickerCard')
  },
  data () {
    return {
      options: {
        sortBy: [],
        sortDesc: [true],
        mustSort: true
      },
      headers: [
        { text: 'Escalate', value: 'escalate' },
        { text: 'Asset', value: 'pipeline_name', divider: true },
        { text: 'Incident Name', value: 'incident_name', divider: true },
        { text: 'Incident Description', value: 'incident_description', divider: true },
        { text: 'Absolute Distance [m]', value: 'absolute_distance', divider: true },
        { text: 'Distance to pipeline [m]', value: 'distance_to_pipeline', divider: true },
        { text: 'Date of notification', value: 'date_of_notification', divider: true },
        { text: 'Date of occurance', value: 'date_of_occurance', divider: true },
        { text: 'Last update', value: 'updated_at', divider: true },
        { text: 'Severity', value: 'severity', divider: true, align: 'center' },
        { value: 'actions' },
        { value: 'data-table-expand' }
      ],
      expanded: [],
      icons: { mdiChevronDown, mdiChevronUp },
      dialog: false,
      item: undefined,
      filterEscalated: false
    }
  },
  computed: {
    ...mapState({
      loading: state => state.eprs.loading,
      items: state => state.eprs.items,
      success: state => state.eprs.success,
      error: state => state.eprs.error
    }),
    filteredItems () {
      if (!this.filterEscalated) return this.items
      else return this.items.filter(i => i.escalated)
    },
    UTC () {
      return moment().format('UTC Z')
    }
  },
  async beforeMount () {
    Promise.all([this.fetch(), this.fetchAllReportsMeta()])
  },
  beforeDestroy () {
    console.warn('for demo purposes, map cache is disabled')
    this.$store.state.mapbox.map = null
    this.$store.state.mapbox.layerObjs = []
    this.$store.state.mapbox.sourceObjs = []
  },
  methods: {
    ...mapMutations({
      setDrawerItems: 'app/setDrawerItems',
      setIncidentsToggle: 'eprs/SET_MAP_TOGGLE',
      unset_messages: 'eprs/UNSET_MESSAGES'
    }),
    ...mapActions({
      fetch: 'eprs/socket_fetchAllIncidents',
      fetchAllReportsMeta: 'eprs/socket_fetchAllReportsMeta',
      delete: 'eprs/socket_deleteIncident',
      update: 'eprs/socket_updateIncident'
    }),
    startEscalate (item) {
      this.item = item
      this.dialog = true
    },
    escalate (selectedTimestamp) {
      this.update({
        incident_id: this.item._id,
        date_of_occurance: selectedTimestamp,
        escalated: true
      })
        .finally(() => {
          this.dialog = false
          this.$router.go()
        })
    },
    newItem () {
      this.$router.push('incident/new')
    },
    formatDate (timestamp) {
      return moment(timestamp).format('DD MMM YYYY HH:mm:ss')
    },
    getLastUpdate (item) {
      if (!item.assessments.length) return 'N/A'
      const assessment = _.last(item.assessments).data
      const timestamp = Object.values(assessment).find(o => o.key === 'assessment-time').value
      return this.formatDate(timestamp)
    },
    getSeverityValue (item) {
      const assessments = item.assessments
      if (assessments.length === 0) return ''
      const severities = assessments.map(a => a.data.find(e => e.key === 'severity').value)
      return Math.max(...severities)
    },
    getColor (item) {
      const severity = this.getSeverityValue(item)
      switch (severity) {
        case 0:
        case 1:
        case 2:
          return 'success darken-2'

        case 3:
          return 'warning'

        case 4:
          return 'warning darken-1'

        case 5:
          return 'error darken-1'

        default:
          return 'grey'
      }
    },
    getActions (item) {
      const actions = [
        {
          label: 'Edit',
          icon: 'edit',
          to: `incident/edit/${item._id}`
        }
      ]

      if (item?.assessments?.length) {
        actions.push({
          label: 'Details',
          icon: 'find_in_page',
          to: `incident/${item._id}/assessment`
        })
      }

      if (item?.escalated) {
        actions.push({
          label: 'New assessment',
          icon: 'addchart',
          to: `incident/${item._id}/assessment/new`
        })
      }

      if (!item?.assessments?.length) {
        actions.push({ divider: true })
        actions.push({
          label: 'Permanently delete incident',
          icon: 'delete_forever',
          to: `incident/${item._id}/delete`
        })
      }

      return actions
    }
  }
}
</script>

<style>
.incident-data-table {
  margin-bottom: 6rem;
}
</style>
